var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-productdetailsdialog"},[_c('v-dialog',{attrs:{"max-width":"800","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.product)?_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","lg":"4","xl":"3"}},[(_vm.hasImage)?_c('v-img',{staticClass:"mb-6",attrs:{"src":_vm.product.imageUrl,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1)]},proxy:true}],null,false,3903726813)}):_c('v-img',{staticClass:"mb-6",attrs:{"src":require("../assets/product-placeholder.png"),"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1)]},proxy:true}],null,false,3903726813)}),_c('v-select',{staticClass:"mb-4 mt-8",attrs:{"items":_vm.packagingItems,"label":_vm.packagingLabel,"hint":!['mq', 'pz'].includes(_vm.product.um)
                ? 'Prezzo e incidenza per queste confezioni' : '',"persistent-hint":!['mq', 'pz'].includes(_vm.product.um),"readonly":_vm.packagingItems.length === 1,"outlined":"","dense":""},model:{value:(_vm.packaging),callback:function ($$v) {_vm.packaging=$$v},expression:"packaging"}}),(_vm.userPricelist)?_c('v-text-field',{staticClass:"mt-0",attrs:{"readonly":"","label":"Prezzo","filled":"","outlined":"","reverse":"","dense":""},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}):_vm._e(),(!['mq', 'pz'].includes(_vm.product.um)
                && (!_vm.product.hasOwnProperty('standalone') || _vm.product.standalone === true))?_c('v-text-field',{attrs:{"readonly":"","label":"Consumo","filled":"","outlined":"","reverse":"","dense":""},model:{value:(_vm.consumption),callback:function ($$v) {_vm.consumption=$$v},expression:"consumption"}}):_vm._e(),(_vm.userPricelist
              && !['mq', 'pz'].includes(_vm.product.um)
              && (!_vm.product.hasOwnProperty('standalone') || _vm.product.standalone === true))?_c('v-text-field',{staticClass:"mt-0",attrs:{"readonly":"","label":"Incidenza","filled":"","outlined":"","reverse":"","dense":""},model:{value:(_vm.incidence),callback:function ($$v) {_vm.incidence=$$v},expression:"incidence"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":'url' in _vm.product === false || !_vm.product.url},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"text":"","outlined":"","small":"","block":"","disabled":'url' in _vm.product === false || !_vm.product.url},on:{"click":function($event){return _vm.onDownload()}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Scheda tecnica ")],1)]}}],null,false,3898381976)},[_c('span',[_vm._v("Scarica la scheda tecnica del prodotto")])])],1),_c('v-col',{attrs:{"cols":"12","md":"7","lg":"8","xl":"9"}},[_c('span',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.product.name[_vm.userLanguage])}}),_c('div',{staticClass:"mt-3 pb-3",domProps:{"innerHTML":_vm._s(_vm.product.details[_vm.userLanguage])}}),(_vm.product.consumptionType === 'variable')?[_c('v-select',{attrs:{"items":_vm.product.consumptionranges.map(function (cr, index) { return ({
                  text: cr.name,
                  value: index,
                }); }),"label":"Fascia di consumo","outlined":"","dense":""},on:{"change":function($event){return _vm.onUpdate()}},model:{value:(_vm.consumptionRange),callback:function ($$v) {_vm.consumptionRange=$$v},expression:"consumptionRange"}})]:_vm._e(),(['colors', 'width'].includes(_vm.product.priceType))?[_c('v-select',{attrs:{"items":_vm.product.priceranges.map(function (pr, index) { return ({
                  text: pr.name,
                  value: index,
                }); }),"label":"Fascia di prezzo","outlined":"","dense":""},on:{"change":function($event){_vm.color = null; _vm.colorlist = null; _vm.onUpdate()}},model:{value:(_vm.priceRange),callback:function ($$v) {_vm.priceRange=$$v},expression:"priceRange"}}),(_vm.product.priceType === 'colors')?_c('v-list',{staticClass:"pt-0 mb-5",attrs:{"two-line":""}},[(_vm.color)?_c('v-list-item',{staticClass:"px-1",on:{"click":_vm.onSelectColor}},[_c('v-list-item-avatar',{staticClass:"my-1"},[_c('v-sheet',{attrs:{"tile":"","height":"40","width":"60","color":("rgba(" + (_vm.color.r) + ", " + (_vm.color.g) + ", " + (_vm.color.b) + ", 1)")}})],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.color.code)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.colorlist.name)}})],1)],1):_c('v-list-item',{staticClass:"px-1",on:{"click":_vm.onSelectColor}},[_c('v-list-item-avatar',{staticClass:"my-1"},[_c('v-icon',{staticClass:"grey lighten-2 white--text"},[_vm._v("mdi-eyedropper-variant")])],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v("Scegli il colore")])],1)],1)],1):_vm._e()]:_vm._e(),(!_vm.isSelected
                && (_vm.product.hasOwnProperty('combined') && _vm.product.combined === true)
                && (_vm.product.hasOwnProperty('standalone') && _vm.product.standalone === false))?_c('v-alert',{attrs:{"text":"","dense":"","type":"info","border":"left"}},[_vm._v(" Utilizzabile solamente se combinato con altri prodotti. Verrà selezionato automaticamente quando necessario. ")]):_vm._e(),(_vm.isSelected && _vm.isCombined)?_c('v-alert',{attrs:{"text":"","dense":"","type":"orange","border":"left"}},[_vm._v(" Prodotto selezionato automaticamente perché richiesto da un altro prodotto in elenco. ")]):_vm._e(),(_vm.requiredProducts.length === 1)?_c('v-alert',{attrs:{"text":"","dense":"","type":"info","border":"left"}},[_vm._v(" Va utilizzato combinato con un altro prodotto ("+_vm._s(_vm.requiredProductsNames)+") che verrà selezionato automaticamente. ")]):_vm._e(),(_vm.requiredProducts.length > 1)?_c('v-alert',{attrs:{"text":"","dense":"","type":"info","border":"left"}},[_vm._v(" Va utilizzato combinato con altri prodotti ("+_vm._s(_vm.requiredProductsNames)+") che verranno selezionati automaticamente. ")]):_vm._e(),((_vm.product.hasOwnProperty('combined') && _vm.product.combined === true))?_c('v-alert',{attrs:{"text":"","dense":"","type":"info","border":"left"}},[_vm._v(" Per l'utilizzo combinato il consumo e l'incidenza verranno calcolati in fase di preventivo. ")]):_vm._e(),(!_vm.isSelected
                && _vm.product.hasOwnProperty('combined') && !!_vm.product.combined
                && (!_vm.product.hasOwnProperty('standalone') || !!_vm.product.standalone))?_c('v-alert',{attrs:{"text":"","dense":"","type":"info","border":"left"}},[_vm._v(" Utilizzabile sia da solo che combinato con altri prodotti. In quel caso il consumo e l'incidenza verranno calcolati in fase di preventivo. ")]):_vm._e()],2)],1)],1),_c('v-divider'),_c('v-card-actions',[(!_vm.select)?_c('v-spacer'):_vm._e(),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.loading},on:{"click":_vm.onCancel}},[_vm._v(" Chiudi ")]),(_vm.select)?_c('v-spacer'):_vm._e(),(_vm.select && _vm.quotation
          && (!_vm.product.hasOwnProperty('standalone') || _vm.product.standalone === true))?_c('v-btn',{attrs:{"color":"success","text":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.onSelect()}}},[_vm._v(" Aggiungi ")]):_vm._e()],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.colorDialog),callback:function ($$v) {_vm.colorDialog=$$v},expression:"colorDialog"}},[(_vm.product)?_c('v-card',[_c('v-card-title',[_vm._v("Seleziona il colore")]),_c('v-card-text',[_c('p',[_vm._v("Scegliendo un colore verrà selezionata automaticamente la fascia di prezzo corrispondente.")]),_c('ColorSelector',{attrs:{"product":_vm.product},on:{"onAnswer":_vm.onColor}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }