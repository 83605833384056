var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-products"},[(_vm.products.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","placeholder":"Cerca...","append-icon":"mdi-magnify","clearable":"","dense":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.phases,"item-text":"name","item-value":"id","placeholder":"Tutte le fasi di lavorazione","outlined":"","clearable":"","dense":""},model:{value:(_vm.filters.phase),callback:function ($$v) {_vm.$set(_vm.filters, "phase", $$v)},expression:"filters.phase"}})],1),(_vm.isSuperUser)?_c('v-col',{staticClass:"archived-switch-continer",attrs:{"cols":"12","md":"6"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"color":"orange","label":"Mostra archiviati"},model:{value:(_vm.filters.archived),callback:function ($$v) {_vm.$set(_vm.filters, "archived", $$v)},expression:"filters.archived"}})],1):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredProducts,"item-key":"id","items-per-page":20,"sort-by":("name." + _vm.userLanguage),"sort-desc":false,"footer-props":{
            itemsPerPageOptions: [10, 20, 30, -1],
          }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name[_vm.userLanguage])+" ")]}},{key:"item.phases",fn:function(ref){
          var item = ref.item;
return [_c('ProductPhases',{attrs:{"product":item}})]}},{key:"item.combined",fn:function(ref){
          var item = ref.item;
return [(!item.hasOwnProperty('standalone')
              || item.standalone === true)?_c('v-icon',{attrs:{"small":"","color":"secondary"}},[_vm._v("mdi-bitbucket")]):_vm._e(),(item.combined)?_c('v-icon',{attrs:{"small":"","color":"secondary"}},[_vm._v("mdi-yin-yang")]):_vm._e()]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(_vm.isSuperUser)?[(item.archived)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"orange"}},[_vm._v(" mdi-archive ")]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-list-item-title',[_vm._v("Elimina")])],1)],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":'url' in item === false || !item.url},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"disabled":'url' in item === false || !item.url,"dark":'url' in item && !!item.url,"fab":"","x-small":"","depressed":"","color":"secondary"},on:{"click":function($event){return _vm.onDownload(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Scarica la scheda tecnica del prodotto")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.onDetails(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Mostra i dettagli del prodotto")])])],2)]}}],null,true)})],1)],1)],1):_c('v-alert',{attrs:{"text":"","prominent":"","type":"success","border":"left"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center"},[_c('span',[_vm._v("Al momento non è presente nessun prodotto.")]),(_vm.isSuperUser)?_c('v-btn',{staticClass:"mt-4 mt-sm-0",attrs:{"color":"success","to":{ name: 'NuovoProdotto' }}},[_vm._v("Aggiungi un prodotto")]):_vm._e()],1)],1)],1),_c('ProductDetailsDialog',{attrs:{"product":_vm.selected,"select":false},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}}),_c('DeleteProductDialog',{attrs:{"product":_vm.selected},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }